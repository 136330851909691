<template>
  <div class="container">
    <div class="row">
      <div class="col-md-11">
        <h2 class="title_lv_2">БАЗА ПРИЛОЖЕНИЙ</h2>
        <h1 class="title_lv_1">Выберите приложение</h1>
      </div>
      <div class="col-md-1">
        <Button @click="logOut()" icon="pi pi-user" label="Выйти" severity="info" outlined />

      </div>

      <div class="col-md-4" v-for='(app, index) of this.APPLICATION' :key='index'>
        <Card style="margin-top:5%;">
          <template #title><input type="text" @change="updateApp(app)" class="tittle" v-model="app.name" :readonly="!userIsAdmin"></template>
          <template #content>
              <p>
                  <input type="textarea" @change="updateApp(app)" class="description"  v-model="app.description" :readonly="!userIsAdmin">
              </p>
            <p> version
              <input type="textarea" @change="updateApp(app)" class="description"  v-model="app.version" :readonly="!userIsAdmin">
            </p>
          </template>

          <template  #footer >
            <Button label="Перейти" icon="pi pi-angle-double-right" @click="routeInfo(app.id)" style="margin-right:5%;" text  />
            <Button label="Удалить" v-if="this.userIsAdmin" icon="pi pi-fw pi-trash"  @click="this.visible = true, this.deletedData = app" severity="danger" text  />
<!--                       <div class="card flex justify-content-center" @click="this.deletedData = app">
                        <PanelMenu :enter="this.deletedData = app" :model="items" class="w-full md:w-25rem" />
                    </div> -->
        </template>
      </Card>
      </div>

      <Dialog v-model:visible="this.visible" modal header="Удалить приложение?" :style="{ width: '50vw' }">
          <p>
            Все данные будут удалены безвозвратно, вместе со всеми записями в приложении.
          </p>
          <template #footer>
              <Button label="НЕТ" icon="pi pi-times" @click="this.visible = false" text />
              <Button label="ДА" icon="pi pi-check" @click="this.visible = false, DeletedApplication(this.deletedData), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });" autofocus />



          </template>
      </Dialog>

      <div class="col-md-4" v-if="this.userIsAdmin">
        <div class="box_add" @click="this.CreateApplication(this.app), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });"><i class="ico_add"></i></div>
      </div>
    </div>
    
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import Card from 'primevue/card';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import PanelMenu from 'primevue/panelmenu';

export default {
  name:'',
  components: {
    Card,
    Button,
    SplitButton,
    Dialog,
    PanelMenu,
  },
  data(){
    return{
      app:{name: "Новое приложение", description:"Описание", creator:this.$store.state.CurrentUser.id},
      visible:false,
      deletedData:{},
      // items: [
      //       {
      //           label: 'Настройки',
      //           icon: 'pi pi-spin pi-cog',
      //           items: [
      //               {
      //                   label: 'Перейти',
      //                   icon: 'pi pi-angle-double-right',
      //                   command: () => {
      //                       this.routeInfo(this.deletedData.id)
      //                   }
      //               },
      //               {
      //                   label: 'Удалить',
      //                   icon: 'pi pi-fw pi-trash',
      //                   command: () => {
      //                       this.visible = true   
      //                   }
      //               },
      //           ]
      //       },
      //   ],
    }
  },
  mounted(){
    this.GET_APLICATION_API()
  },
  created(){
    
  },
  watch:{

  },
  methods:{
    logOut(){
      localStorage.removeItem('Authorization')
      this.$router.push({ path: `/authorization/`, query: {}})
    },
    routeInfo(id){
      this.saveAppID(id)
      this.$router.push({ path: `/application/${id}`, query: {}})
    },
    updateApp(app){
      if(this.userIsAdmin){
        this.UpdateApplication(app)
        this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });
      } else {

      }
    },
  ...mapMutations({
    UpdateApplication:'UpdateApplication',
    CreateApplication:'CreateApplication',
    DeletedApplication:'DeletedApplication',
    saveAppID:'saveAppID',
  }),
  ...mapActions({
    GET_APLICATION_API:'GET_APLICATION_API',
  }),
  },
  computed:{
    ...mapGetters([
      'APPLICATION',
      'userIsAdmin',
  ]),
  }
};
</script>

<style scoped>
.tittle {
  border:none;
  outline:none;
  font-size:24px;
  font-weight: bold;
}
.tittle:focus, .description:focus{
  border-bottom: 1px solid #000;
}
.description {
  border:none;
  outline:none;
  font-size:16px;

}
.title_lv_2{
    color: #444bd3;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
}
.title_lv_1{
    color: #121127;
    text-align: left;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10%;
}
.box_add {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 5%;
    box-shadow: -10px 20px 30px rgb(18 17 39/8%);
    position: relative;
    cursor: pointer;
    min-height: 250px;
}
.ico_add, .ico_add:before {
    width: 22px;
    height: 3px;
    background: #121127;
    margin: auto;
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.ico_add:before {
    content: "";
    transform: rotate(90deg);
}
</style>

