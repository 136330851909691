<template>
  <div class="container-fluid">
  	<div class="row">
        <div class="col-md-2 mt-5">
          <div class="card flex justify-content-center">
                <PanelMenu :model="getNav" class="w-full md:w-25rem" />
            </div>
        </div>
        <div class="col-md-10">
        	<div class="row">
	          <div class="col-md-12">
	            <h1>Анонс мероприятия</h1>
	            <div class="btn-box">
	              <div class="row">
	                <div class="col-md-4">
	                  <div class="button-box">
	                    <div><Button icon="pi pi-plus" @click="this.CreateEvent(this.createdEvent), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 })" severity="success" aria-label="Create" /></div>
	    <!--                <div class="ml-5"><Button calss="ml-5" icon="pi pi-check" severity="secondary" aria-label="All" /></div>
	                    <div class="ml-5"><Button calss="ml-5" icon="pi pi-trash" severity="danger" aria-label="Dell" /></div> -->
	                  </div>
	                  
	                </div>
	                <div class="col-md-8">
	                </div>
	              </div>
	            </div>
	          </div>
        	</div>

          <div class="col-md-12">
            <div class="nav-box">
              <div class="check-box"> 
                <div class=""><Checkbox v-model="selectAll" @change="addTobasket()" :binary="true" />
                <label for="ingredient1" class="ml-2"> Выделить все </label></div>
                <Button  icon="pi pi-trash" label="Удалить выделенное" @click="this.delAllvisible = true" aria-label="dell" severity="danger" />
              </div>
            </div>
          </div>

          	<div class="col-md-12">
            <div class="card-table" v-for='(event, index) of this.APP_EVENT' :key='index'>
              <div class="row">
                <div class="col-md-3" >
                  <div class="img-box">
                    <img :src="event.image" alt="">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="description-box">
                    <div class="tittle">{{event.tittle}}</div>
                    <div class="tittle"><a :href="event.link" target="_blank">Ссылка</a> </div>
                    <div class="tittle"> {{event.description}}</div>
                    <div class="tittle"> С {{event.date_on || 'не указано'}} ПО {{event.date_off || 'не указано'}}</div>
                    <ul class='message'>
  <!--                     <li><InlineMessage v-if="app.description != ''" severity="success">Есть Описание</InlineMessage></li>
                      <li><InlineMessage v-if="app.description == ''" severity="error">Описания нет</InlineMessage></li>
                      <li><InlineMessage v-if="!app.video" severity="error">Видео не добавлено</InlineMessage></li>
                      <li><InlineMessage v-if="app.video" severity="success">Есть видео</InlineMessage></li> -->
                      
                    </ul>
  <!--                  <InlineMessage v-if="app.description != ''" severity="success">Есть Описание</InlineMessage>
                    <InlineMessage v-if="app.description == ''" severity="error">Описания нет</InlineMessage>
                    <InlineMessage v-if="!app.video_link" severity="error">Видео не добавлено</InlineMessage>
                    <InlineMessage v-if="app.video_link" severity="success">Есть видео</InlineMessage> -->
                  </div>
                </div>
                <div class="col-md-3 offset-md-2">
                  <div class="table-nav-box">
                    <div class="checked">
                      <Checkbox v-model="selectedID" :inputId="event.tittle" :value="event.id" />
                    </div>
                    <div class="button">
                      <Button  icon="pi pi-pencil" @click="goEdit(event.id)"  aria-label="edit" severity="secondary" />
                      
                    </div>
                    <div class="button"><Button @click="this.visible = true, this.dellData = event.id" icon="pi pi-trash" severity="danger" aria-label="Dell"/></div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

        </div>
  	</div>


      <Dialog v-model:visible="this.visible" modal header="Удалить Контент?" :style="{ width: '50vw' }">
            <p>
              Все данные будут удалены безвозвратно, вместе свидео..
            </p>
            <template #footer>
                <Button label="НЕТ" icon="pi pi-times" @click="this.visible = false" text />
                <Button label="ДА" icon="pi pi-check" @click="this.visible = false, DeletedEvent(this.dellData), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });" autofocus />



            </template>
        </Dialog>
        <Dialog v-model:visible="this.delAllvisible" modal header="Удалить Контент?" :style="{ width: '50vw' }">
            <p>
              Все выделенные данные будут безвозвратно удалены!
            </p>
            <template #footer>
                <Button label="НЕТ" icon="pi pi-times" @click="this.delAllvisible = false" text />
                <Button label="ДА" icon="pi pi-check" @click=" this.delAllvisible = false, this.selectAll = false, DeletedManyEvent(this.selectedID), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });" autofocus />



            </template>
        </Dialog>


  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import PanelMenu from 'primevue/panelmenu';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';

export default {
  name:'',
  components: {
  	PanelMenu,
  	Button,
  	Checkbox,
  	Dialog,
  },
  data(){
  	return{
      selectAll:false,
      selectedID:[],
      delAllvisible:false,
      visible:false,
      dellData:null,
      createdEvent:{
        app_id:this.$route.params.id, 
        image:'photos/noImage.png', 
        tittle:'Заголовок События',
        link:'Ссылка',
        description:'Описание',
      },
  	}
  },
  mounted(){
  	this.GET_APLICATION_DETAILS_API()
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
  	CreateEvent:'CreateEvent',
  	DeletedManyEvent:'DeletedManyEvent',
  	DeletedEvent:'DeletedEvent',
	}),
	...mapActions({
		GET_APLICATION_DETAILS_API:'GET_APLICATION_DETAILS_API',
	}),
  addTobasket(){    
    let data = this.APP_EVENT
    console.log(data)
    for (let i=0; i < data.length; i++) {
      let index = this.selectedID.indexOf(data[i].id)
      if (this.selectAll){
        this.selectedID.push(data[i].id)
      } else {
        this.selectedID.splice(index, 1);     
      }
        
    }
    let listID = this.selectedID.filter((item, index) => {
        return this.selectedID.indexOf(item) === index
    });
    this.selectedID = listID
    console.log(this.selectedID )
  },
  goEdit(id){
    this.$router.push({ path: `/EventEditor/${id}`, query: {}})
  },
  },
  computed:{
  	...mapGetters([
  		'APP_EVENT',
      'getNav',
	]),
  }
};
</script>

<style scoped>
.btn-box {
  background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    display: block;
    text-align: right;
}
.button-box {
  display:flex;
  align-items: flex-start;
}
.table-nav-box {
  margin-top: 5%;
  padding-right: 10%;
}
.checked {
  display:block;
  text-align: right;
}
.button {
  margin:10px 0px 10px 0px;
  display: block;
  text-align: right;
}
.img-box {
  padding:5%;
  display:flex;
  align-items: center;
  height:100% ;
}
.img-box img{
  width:100%;
  height:auto;
}
.tittle {
  font-size:18px;
  margin-top: 5%;
}
.nav-box {
  margin-left: 2%;
  margin-top: 1%;
}
.check-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

