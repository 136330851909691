<template>
  <div class="editDetails">
  	<div class="container-fluid">
  		<div class="row">
  			<div class="col-md-2">
			<div class="card flex justify-content-center">
		        <PanelMenu :model="getNav" class="w-full md:w-25rem" />
		    </div>
  			</div>
  			<div class="col-md-10">
  				<TabMenu v-model:activeIndex="active" :model="items" />

  				<div class="row" v-if="active === 0">
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Изменить название проекта</h2>
		  					<Textarea v-model="this.$store.state.Offer.offerEdit.tittle" autoResize rows="5" cols="30" />
		  				</div>
  					</div>
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Отображаемые изменения</h2>
		  					<p>{{this.$store.state.Offer.offerEdit.tittle}}</p>
		  				</div>
  					</div>
  				</div>

  				<div class="row" v-if="active === 1">
  					<div class="col-md-6" >
  						<h2>Изменить описание проекта</h2>
						<Textarea v-model="this.$store.state.Offer.offerEdit.link" autoResize rows="5" cols="30" />

  					</div>
  					<div class="col-md-6">
  						<h2>Отображаемые изменения</h2>
  						<a :href="this.$store.state.Offer.offerEdit.link" target="_blank">{{this.$store.state.Offer.offerEdit.tittle}}</a>
  					</div>
  				</div>

  				<div class="row" v-if="active === 2">
  					<div class="col-md-12">
  					<div class="row">
					<div class="col-md-6">
						<h2>Текущее изображение</h2>
					  	<div class="upload_file">
					<!-- this.$store.state.options.img_path + -->
					    <img v-if="this.$store.state.Offer.offerEdit.image" :src="this.$store.state.Offer.offerEdit.image" alt="">
					    
					    <div class="input__wrapper" >
					   <input type="file" @change="handleFileUpload()" :id="this.$store.state.Offer.offerEdit.id" class="input">
					   <label :for="this.$store.state.Offer.offerEdit.id" class="input__file-button">
					      <span class="input__file-icon-wrapper"></span>
					      <span class="input__file-button-text">Выберите файл</span>
					   </label>
					    </div>
					    <div   class="del input__file-button" @click="DeletedFile()">
					        <span class="input__file-icon-wrapper"></span>
					        <span class="input__file-button-text" >Удалить</span>
					    </div>
					  </div>
						</div>
						<div class="col-md-6">
							<div class="upload_file">
								<h2>Загруженное изображение</h2>
								<img :src="this.visionFile" alt="">
							</div>
						</div>
  					</div>
  					</div>	
  				</div>

  			</div>
  			<div class="col-md-8 offset-md-2" >
  				<div class="" style="text-align:right;">
  					<Button @click="submitFile()" label="Сохранить Изменение" severity="success" />
  				</div>
  				
  			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { useToast } from 'primevue/usetoast';


import TabMenu from 'primevue/tabmenu';
import PanelMenu from 'primevue/panelmenu';
import InputText from 'primevue/inputtext';

import Button from 'primevue/button';
import Editor from 'primevue/editor';
import Textarea from 'primevue/textarea';


export default {
  name:'',
  components: {
  	TabMenu,
  	PanelMenu,
  	InputText,
  	Button,
  	Editor,
  	Textarea
  },
  data(){
  	return{
  		active: 0,
  		edit:{},
        items: [
            {
                label: 'Название',
                icon: 'pi pi-fw pi-pencil'
            },
            {
                label: 'Ссылка',
                icon: 'pi pi-fw pi-link'
            },
            {
                label: 'Изображение',
                icon: 'pi pi-fw pi-image'
            },
        ],
        files:'',
        noFiles:'',
        filesInfo:'',
        visionFile:[],
  	}
  },
  mounted(){
  	

  	this.GET_OFFER_API(this.$route.params.id)

  	console.log(this.$store.state.Offer.offerEdit)
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
  		UploadDataOffers:'UploadDataOffers',
	}),
	...mapActions({
		GET_OFFER_API:'GET_OFFER_API',
		
	}),
	    submitFile(){
	    if (this.filesInfo == ''){
	    	this.UploadDataOffers(this.$store.state.Offer.offerEdit)
	    	this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
	    } if(this.filesInfo == 'update' || this.filesInfo == 'dell') {
        let formData = new FormData()
        if (this.filesInfo == 'update'){
        	formData.append('image', this.files)
        } if(this.filesInfo == 'dell') {
        	formData.append('image', this.noFiles)
        }
        
        formData.append('tittle', this.$store.state.Offer.offerEdit.tittle)
        formData.append('link', this.$store.state.Offer.offerEdit.link)
        
        this.axios({
            method: 'PUT',
          	headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `${this.$store.state.options.address + this.$store.state.options.api.UploadImageOffer + this.$route.params.id}/`,
            data:formData,
            }).then(response =>{
                this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
                this.UploadDataOffers(this.$store.state.Offer.offerEdit)
            })
            .catch(function(error){
                console.log(error)         
        }) 
        }     
    },
    handleFileUpload(){
    	let that = this
    	that.files = event.target.files[0]
        let reader = new FileReader()
        reader.onload = function (e) {
            that.visionFile = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);      
        console.log(that.files)
        that.filesInfo = 'update'     
    },
    DeletedFile(){
        this.$store.state.Offer.offerEdit.image = ''
        this.filesInfo = 'dell'
        // this.axios({

        //     method: 'PUT',
        //     url: `${this.$store.state.options.address + this.$store.state.options.api.applicationDetails + this.$route.params.id}/`,
        //     data:data,
        //     }).then(response =>{
        //         console.log(response.data)
        //         this.GET_EDIT_DETAILS_API(this.$route.params.id)
        //     })
        //     .catch(function(error){
        //         console.log(error)         
        // }) 
    }
  },
  computed:{
  	...mapGetters([
  		'GET_OFFERS',
      'getNav',

	]),

  }
};
</script>

<style scoped>
.upload_file {
	text-align:center;
}
img {
    max-width:100%;
    max-height:400px;
}
.input{
    display:none;
}
.input__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background: #121127;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}
</style>

