import { createStore } from 'vuex'
import axios from 'axios'
import options from '../options'
import ApplicationEdit from '../ApplicationEdit/index.js'
import Offer from '../Offer/index.js'
import Event from '../Event/index.js'


export default ({
  state: {
    applicationDetails:[],
    id:localStorage.getItem('appID'),
    pageSize:30,
    pageCount: 0,
    paginationMassive:[],
    page:1,
    paginateAppDetails:[],
    offers:[],
    event:[],
    audio:[],
    coachEdit:[],
    tagActive:'',
    search:'',
  },
  modules: {
    options,
    ApplicationEdit,
    Offer,
    Event,
  },
  mutations: {
    activeTag(state, tag){
      state.tagActive = tag
    },
    setIdApp(state, id){
      state.id = localStorage.getItem('appID')
    },
    nextPage(state, page){
      state.page = page
      this.commit('itemPaginate')
    },
    set_applicationDetails(state, data){
      state.applicationDetails = data
      console.log(data)
    },
    set_coach(state, data){
      state.coachEdit = data
    },
    itemPaginate(state){
        let paginationMassive = state.applicationDetails?.app?.slice().reverse()
        state.paginationMassive = paginationMassive
        state.pageCount = Math.ceil(state.paginationMassive.length / state.pageSize)
        state.paginationMassive = _.chunk(state.paginationMassive, state.pageSize)
        state.paginateAppDetails = state.paginationMassive[state.page -1] || state.paginationMassive[0]
        // console.log(state.paginationMassive, 'Пагинация')
        // console.log(state.paginateAppDetails)
    },
    itemFilterTag(state, tag){
      console.log(state.search)
        let paginationMassive = state.applicationDetails?.app?.slice().reverse()
        state.paginationMassive = paginationMassive.filter(app => app.tag.indexOf(state.tagActive) > -1 && app.tittle.toLowerCase().indexOf(state.search.toLowerCase()) > -1)
        state.pageCount = Math.ceil(state.paginationMassive.length / state.pageSize)
        state.paginationMassive = _.chunk(state.paginationMassive, state.pageSize)
        state.paginateAppDetails = state.paginationMassive[state.page -1] || state.paginationMassive[0]
        // console.log(state.paginationMassive, 'Пагинация')
        // console.log(state.paginateAppDetails)
    },
    CreateApplicationDetails(state, data){
      axios({
          method:'POST',
          headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
          url:`${options.state.address + options.state.api.AddNewAppDetails}`,
          data:data,
      }).then(resp => {

        this.dispatch('GET_APLICATION_DETAILS_API');
      })
    },
    UploadDataDetails(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.UploadDataDetails + data.id}/`,
        data:data,
      }).then(resp => {

        this.dispatch('GET_EDIT_DETAILS_API', data.id);
      })  
    },
    UpdateApplicationDetails(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.applicationDetails + state.id}/`,
        data:data,
      }).then(resp => {

        this.dispatch('GET_EDIT_DETAILS_API', data.id);
      })  
    },
    DeletedApplicationDetails(state, data){
      axios({
        method:'DELETE',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.applicationDetails + data}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    DeletedManyAppDetails(state, data){
      axios({
        method:'POST',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.DeletedManyAppDetails}`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    /*************************OFFERS**************************************/
    set_offers(state, data){
      state.offers = data
      console.log(data)
    },
    CreateOffer(state, data){
      axios({
          method:'POST',
          headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
          url:`${options.state.address + options.state.api.AddNewOffer}`,
          data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })
    },
    DeletedOffer(state, data){
      axios({
        method:'DELETE',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.Offer + data}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    DeletedManyOffers(state, data){
      axios({
        method:'POST',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.DeletedManyOffer}`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    UploadDataOffers(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.UploadDataOffer + data.id}/`,
        data:data,
      }).then(resp => {

        this.dispatch('GET_OFFER_API', data.id);
      })
      },  
    /*************************OFFERS**************************************/
    /*************************EVENT**************************************/
    CreateEvent(state, data){
      axios({
          method:'POST',
          headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
          url:`${options.state.address + options.state.api.AddNewEvent}`,
          data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })
    },
    DeletedManyEvent(state, data){
      axios({
        method:'POST',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.DeletedManyEvent}`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    DeletedEvent(state, data){
      axios({
        method:'DELETE',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.Event + data}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    UploadDataEvent(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.UploadDataEvent + data.id}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_EVENT_API', data.id);
      })
      },  
    /*************************EVENT**************************************/

    /*************************AUDIO**************************************/
    // set_audio(state, data){
    //   state.audio = audio
    //   console.log(data)
    // },
    CreateAudio(state, data){
      axios({
          method:'POST',
          headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
          url:`${options.state.address + options.state.api.AddNewAudio}`,
          data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })
    },
    DeletedAudio(state, data){
      axios({
        method:'DELETE',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.Audio + data}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    DeletedManyAudio(state, data){
      axios({
        method:'POST',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.DeletedManyAudio}`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    UploadDataAudio(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.UploadDataAudio + data.id}/`,
        data:data,
      }).then(resp => {

        this.dispatch('GET_AUDIO_API', data.id);
      })
      }, 
    /*************************AUDIO**************************************/
        /*************************COACH**************************************/
    // set_audio(state, data){
    //   state.audio = audio
    //   console.log(data)
    // },
    CreateCoach(state, data){
      axios({
          method:'POST',
          headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
          url:`${options.state.address + options.state.api.AddNewCoach}`,
          data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })
    },
    DeletedCoach(state, data){
      axios({
        method:'DELETE',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.CoachAllInfoView + data}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    DeletedManyCoach(state, data){
      axios({
        method:'POST',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.DeletedManyCoach}`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_DETAILS_API');
      })   
    },
    UploadDataCoach(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.CoachUpdateData + data.id}/`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_COACH_API', data.id);
      })
      }, 
    /*************************COACH**************************************/


  },
  actions: {
    GET_APLICATION_DETAILS_API({state, commit}, data) {
    axios({

          method:'GET',
          headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
          url:`${options.state.address + options.state.api.ApplicationDepth + state.id + '/'}`,
        })
        .then(product => {commit('set_applicationDetails', product.data),
          state.tagActive != '' ? commit('itemFilterTag'): commit('itemPaginate')
        })
        .catch(function(error){
                  console.log(error)         
        })        
      },
    GET_COACH_API({state, commit}, data) {
    axios({
          method:'GET',
          headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
          url:`${options.state.address + options.state.api.CoachAllInfoView + data}/`,
        })
        .then(product => {commit('set_coach', product.data)})
        .catch(function(error){
                  console.log(error)         
        })        
      },
  },
  getters: {
    APPLICATION_DETAILS(state){
      return state.applicationDetails
    },
    APPLICATION_DETAILS_APP(state){
      return state.applicationDetails?.app.reverse()
    },
    APP_PAGINATE_DETAILS(state){
      return state.paginateAppDetails
    },
    APP_OFFER(state){
      return state.applicationDetails?.Offer?.reverse()
    },
    APP_EVENT(state){
      return state.applicationDetails?.Event?.reverse()
    },
    APP_AUDIO(state){
      return state.applicationDetails?.Audio?.reverse()
    },
    APP_COACH(state){
      return state.applicationDetails.Coach
    },
    PAGE_COUNT(state){
      return state.pageCount
    },
    GET_ACTIVE_TAG(state){
      return state.tagActive
    },

  },
})
