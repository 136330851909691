import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router'
import options from './options'


import Application from './Application/index.js'
import ApplicationDetails from './ApplicationDetails/index.js'
import ApplicationEdit from './ApplicationEdit/index.js'
import Offer from './Offer/index.js'
import Event from './Event/index.js'
import Audio from './Audio/index.js'
import {commit} from "lodash/seq";

export default createStore({
  modules: {
    options,
    Application,
    ApplicationDetails,
    ApplicationEdit,
    Offer,
    Event,
    Audio,
  },
  state: {
    items: [
      {
              label: 'Главная',
              icon: 'pi pi-home',
              command: () => {
                router.push('/')
            }
          },
          {
              label: 'Контент приложения',
              icon: 'pi pi-mobile',
              command: () => {
                router.push('/application/' + ApplicationDetails.state.id)
            }
          },
          {
              label: 'Рекламные предложения',
              icon: 'pi pi-comment',
              command: () => {
                router.push('/Offer/' + ApplicationDetails.state.id)
            }
          },
          {
              label: 'Анонсы мероприятий',
              icon: 'pi pi-book',
              command: () => {
                router.push('/Event/' + ApplicationDetails.state.id)
            }
          },
          {
              label: 'Аудио альбомы',
              icon: 'pi pi-volume-up',
              command: () => {
                router.push('/Audio/' + ApplicationDetails.state.id)
            }
          },
          {
              label: 'Коучи',
              icon: 'pi pi-user',
              command: () => {
                router.push('/Coach/' + ApplicationDetails.state.id)
            }
          },
        {
            label: 'Пользователи',
            icon: 'pi pi-user',
            command: () => {
                router.push('/userList/' + ApplicationDetails.state.id)
            }
        },
        ],
      CurrentUser:{},
      allUser:[],
      ApplicationUsers:[],
      userApp:[],
      appID:localStorage.getItem('appID') || 1,
  },
  getters: {
      userIsAdmin(state){
          return state.CurrentUser?.is_staff
      },
      getNav(state){
          return state.CurrentUser?.is_staff ? state.items : state.items.slice(0, -1)
      },
      GET_DATA_ALL_USERS(state){
          return state.allUser
      },
      GET_APP_USERS(state){
          return state.ApplicationUsers
      },
      GET_NEED_USERS_APP(state){
          return state.userApp
      }
  },
  mutations: {
      saveAppID(state, id){
          localStorage.setItem('appID', id)
      },
    notFound(state){
      router.push('/404')
    },
      set_currentUser(state, data){
          state.CurrentUser = data
      },
      setAppUserDetails(state, data){
        state.ApplicationUsers = data
      },
      setUser(state, data){
          state.allUser = data
      },
      updateUserInApp(state, data){
          axios({
              method:'PUT',
              headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
              url:`${options.state.address + options.state.api.optionsAppUser + '/' + state.appID}/`,
              data:data.data,
          }).then(resp => {
              this.dispatch('ApplicationUserDetail');
          })
      },
      setNeedUserApp(state, data){
          state.userApp = data
      },
      createNewUserInApp(state, data){
          axios({
              method:'POST',
              url:`${options.state.address + options.state.api.register}`,
              data:data,
          }).then(resp => {
              router.push('/authorization')
          }).catch(error => console.log(error))
      }

  },
  actions: {
      currentUser({state, commit}){
          axios({
              method:'GET',
              headers:{
                  Authorization:'Token ' + localStorage.getItem('Authorization')
              },
              url:`${options.state.address + options.state.api.currentUser}`,
          })
              .then(product => {commit('set_currentUser', product.data)})
              .catch(function(error){
                  console.log(error)
              })
      },
      GET_ALL_USER({state, commit}, data) {
          axios({
              method:'GET',
              headers:{
                  Authorization:'Token ' + localStorage.getItem('Authorization')
              },
              url:`${options.state.address + options.state.api.allUser}`,
          })
              .then(user => {commit('setUser', user.data)})
              .catch(function(error){
                  console.log(error)
              })
      },
      getNeedUserApp({state, commit}, data) {
          axios({
              method:'GET',
              headers:{
                  Authorization:'Token ' + localStorage.getItem('Authorization')
              },
              url:`${options.state.address + options.state.api.optionsAppUser + '/' + data}/`,
          })
              .then(user => {commit('setNeedUserApp', user.data)})
              .catch(function(error){
                  console.log(error)
              })
      },
      ApplicationUserDetail({state, commit}, data){
          axios({
              method:'GET',
              headers:{
                  Authorization:'Token ' + localStorage.getItem('Authorization')
              },
              url:`${options.state.address + options.state.api.ApplicationUserDetail + state.appID + '/'}`,
          })
              .then(product => {commit('setAppUserDetails', product.data.assign)})
              .catch(function(error){
                  console.log(error)
              })
      },
  },

})
