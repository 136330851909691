<template>
  <div class="registration">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="form">
            <p class="auth_desc">Логин</p>
            <input type="text" v-model="form.username" placeholder="Логин" class="input_auth">
            <p class="auth_desc">Email</p>
            <input type="email" v-model="form.email" placeholder="Email" class="input_auth" >
            <p class="auth_desc">Пароль</p>
            <input type="password" v-model="form.password" placeholder="Пароль" class="input_auth" >
            <button class="auth_btn" @click="createUser()">Регистрация</button>
            <!--   					<button class="auth_btn" @click="checkSession()">Check</button>
                        <button class="auth_btn" @click="session()">session()</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  name:'registration',
  components: {
  },
  data(){
    return{
      form:{
        username:'',
        password:'',
        email:'',
        application_id:this.$route.params.id,
      }
    }
  },
  mounted(){
  },
  created(){

  },
  methods:{
    createUser(){
      this.createNewUserInApp(this.form)
    },
    ...mapMutations({
      createNewUserInApp:'createNewUserInApp',
    }),
    ...mapActions({
    }),
  },
  computed:{
    ...mapGetters([
    ]),
  }
};
</script>

<style scoped>
.input_auth{
  border:1px solid rgba(18, 17, 39, 0.12);
  width:100%;
  padding:15px 0px 15px 20px;
  font-size:18px;
  color:#121127;
  opacity:0.6;
  background:#fff;
  outline:none;
  border-radius:5px;
}
.auth_desc{
  margin-bottom:4px;
  color:#121127;
  font-size:14px;
  font-weight:bold;
  opacity:0.6;
}
.form{
  padding:15%;
  text-align:left;
  margin-top:15%;
}
.auth_btn{
  font-size:20px;
  color:#fff;
  text-align:center;
  background:#444BD3;
  border-radius:5px;
  outline:none;
  border:none;
  width:100%;
  padding:15px 0px 15px 0px;
  cursor:pointer;
  margin-top:32px;
}
</style>

