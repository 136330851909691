<template>
  <div class="editDetails">
  	<div class="container-fluid">
  		<div class="row">
  			<div class="col-md-2">
			<div class="card flex justify-content-center">
		        <PanelMenu :model="getNav" class="w-full md:w-25rem" />
		    </div>
  			</div>
  			<div class="col-md-10">
  				<TabMenu v-model:activeIndex="active" :model="items" />

  				<div class="row" v-if="active === 0">
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Изменить название проекта</h2>
                <Textarea v-model="this.$store.state.ApplicationEdit.appEdit.tittle" :placeholder="edit.tittle" autoResize rows="2" cols="30" />
                <label :class="{'red':this.$store.state.ApplicationEdit.appEdit.tittle?.length>80}" class="validate">Символов: {{this.$store.state.ApplicationEdit.appEdit.tittle?.length}} из 80</label>
		  				</div>
  					</div>
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Отображаемые изменения</h2>
		  					<p>{{this.$store.state.ApplicationEdit.appEdit.tittle}}</p>
		  				</div>
  					</div>
  				</div>

  				<div class="row" v-if="active === 1">
  					<div class="col-md-6" >
  						<h2>Изменить описание проекта</h2>
  						<Editor v-model="this.$store.state.ApplicationEdit.appEdit.description" editorStyle="height: 320px"  >
						</Editor>
  					</div>
  					<div class="col-md-6">
  						<h2>Отображаемые изменения</h2>
  						<div class="" v-html="this.$store.state.ApplicationEdit.appEdit.description"></div>
  					</div>
  				</div>

  				<div class="row" v-if="active === 2">
  					<div class="col-md-6">
  						<h2>Изменить видео проекта</h2>
  						<Textarea v-model="this.$store.state.ApplicationEdit.appEdit.video" autoResize rows="5" cols="30" />
              <div class="alert danger" v-if="validateVideo">{{this.$store.state.ApplicationEdit.appEdit.video === '' ? 'Укажите видео' : 'Такое видео уже добавленно'}} <a v-if="this.$store.state.ApplicationEdit.appEdit.video != '' && showVideoError" class="danger-link" @click="this.$router.push(`/edit/${goDubleVideo}`)">Посмотреть</a></div>
  					</div>
  					<div class="col-md-6">
  						<h2>Отображаемые изменения</h2>
  						<div class="" v-html="this.$store.state.ApplicationEdit.appEdit.video"></div>
  					</div>
  				</div>

  				<div class="row" v-if="active === 3">
  					<div class="col-md-12">
  					<div class="row">
					<div class="col-md-6">
						<h2>Текущее изображение</h2>
					  	<div class="upload_file">
					<!-- this.$store.state.options.img_path + -->
					    <img v-if="this.$store.state.ApplicationEdit.appEdit.image" :src="this.$store.state.ApplicationEdit.appEdit.image" alt="">
					    
					    <div class="input__wrapper" >
					   <input type="file" @change="handleFileUpload()" :id="this.$store.state.ApplicationEdit.appEdit.id" class="input">
					   <label :for="this.$store.state.ApplicationEdit.appEdit.id" class="input__file-button">
					      <span class="input__file-icon-wrapper"></span>
					      <span class="input__file-button-text">Выберите файл</span>
					   </label>
					    </div>
					    <div   class="del input__file-button" @click="DeletedFile()">
					        <span class="input__file-icon-wrapper"></span>
					        <span class="input__file-button-text" >Удалить</span>
					    </div>
					  </div>
						</div>
						<div class="col-md-6">
							<div class="upload_file">
								<h2>Загруженное изображение</h2>
								<img :src="this.visionFile" alt="">
							</div>
						</div>
  					</div>
  					</div>	
  				</div>

          <div class="row" v-if="active === 4">
            <div class="col-md-6">
              <h2>Выбрать рекламу</h2>
              <select class="offers" @change="selectOffer()" v-model="selectedOffer">
                    <option  v-for="(offer, index) of this.APPLICATION_DETAILS.Offer" :value='offer.id' :key="index">{{offer.tittle}}</option>
<!--                     <option :value="null">Убрать рекламу</option> -->
                </select>
                <Button class="mt-5" @click="this.selectedOffer = null, selectOffer()" label="Удалить рекламу" severity="danger" />
            </div>
            <div class="col-md-6" v-if='this.useOffer[0]'>
              <h2 style="text-align:center;">Выбранная реклама</h2>
              <div class="offer-box">
                <div class="offer-img">
                  <img :src="this.useOffer[0].image" alt="">
                </div>
                <div class="offet-link">
                  <a :href="this.useOffer[0].link" target="_blank">{{this.useOffer[0].tittle}}</a>
                </div>
              </div>
            </div>


          </div>

          <div class="row" v-if="active === 5">
            <div class="col-md-6" >
              <h2>Изменить описание проекта</h2>
              <InputText type="text" v-model="this.$store.state.ApplicationEdit.appEdit.tag"/>
            </div>
            <div class="col-md-6">
              <h2>Отображаемые изменения</h2>
              <p>{{this.$store.state.ApplicationEdit.appEdit.tag}}</p>
            </div>
          </div>

          <div class="row" v-if="active === 6">
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Введите имя Автора</h2>
                <Textarea v-model="this.$store.state.ApplicationEdit.appEdit.coach_name" :placeholder="edit.coach_name" autoResize rows="2" cols="30" />
                <label :class="{'red':this.$store.state.ApplicationEdit.appEdit.coach_name?.length>80}" class="validate">Символов: {{this.$store.state.ApplicationEdit.appEdit.coach_name?.length}} из 80</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Отображаемые изменения</h2>
                <p>{{this.$store.state.ApplicationEdit.appEdit.coach_name}}</p>
              </div>
            </div>
          </div>


  			</div>
  			<div class="col-md-8 offset-md-2" >
  				<div class="" style="text-align:right;">
  					<Button @click=" submitFile()"  label="Сохранить Изменение" severity="success" />
  				</div>

  			</div>
  		</div>
  	</div>
  </div>
<!--  @click="submitFile()"-->
</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { useToast } from 'primevue/usetoast';


import TabMenu from 'primevue/tabmenu';
import PanelMenu from 'primevue/panelmenu';
import InputText from 'primevue/inputtext';

import Button from 'primevue/button';
import Editor from 'primevue/editor';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';

export default {
  name:'',
  components: {
  	TabMenu,
  	PanelMenu,
  	InputText,
  	Button,
  	Editor,
  	Textarea,
    Dropdown
  },
  data(){
  	return{
  		active: 0,
  		edit:{},
        items: [
            {
                label: 'Название',
                icon: 'pi pi-fw pi-pencil'
            },
            {
                label: 'Описание',
                icon: 'pi pi-fw pi-align-justify'
            },
            {
                label: 'Видео',
                icon: 'pi pi-fw pi-youtube'
            },
            {
                label: 'Изображение',
                icon: 'pi pi-fw pi-image'
            },
            {
                label: 'Реклама',
                icon: 'pi pi-fw pi-megaphone'
            },
            {
                label: 'Тег',
                icon: 'pi pi-fw pi-link'
            },
          {
            label: 'Автор',
            icon: 'pi pi-fw pi-link'
          }
        ],
        files:'',
        noFiles:'',
        filesInfo:'',
        visionFile:[],
        selectedOffer:this.$store.state.ApplicationEdit.appEdit.offer || null,
        validateVideo:false,
        goDubleVideo:'',
        showVideoError:false,
  	}
  },
  mounted(){
    this.GET_EDIT_DETAILS_API(this.$route.params.id)
    this.GET_APLICATION_DETAILS_API()
  },
  created(){

  },
  methods:{
  ...mapMutations({
  		UploadDataDetails:'UploadDataDetails',
  		UpdateApplicationDetails:'UpdateApplicationDetails'
	}),
	...mapActions({
		GET_EDIT_DETAILS_API:'GET_EDIT_DETAILS_API',
    GET_APLICATION_DETAILS_API:'GET_APLICATION_DETAILS_API',

	}),
   validateVideoDouble(video){

    let dubleVideo = this.APPLICATION_DETAILS.app.filter(ap => { return ap.video === video})
     console.log(dubleVideo)
      dubleVideo.length > 0 ? this.validateVideo = true : this.validateVideo = false
     if(this.validateVideo === true){
       this.goDubleVideo = dubleVideo[0]?.id
       this.showVideoError = true
     } else {
       this.goDubleVideo = ''
       this.showVideoError = false
     }

    // let check = this.APPLICATION_DETAILS.app.filter(app => {app.video === video})
    // console.log('такое видео уже есть', check)
     console.log(this.validateVideo)
    },
	    submitFile(){
      this.validateVideoDouble(this.$store.state.ApplicationEdit.appEdit.video)
      if(this.validateVideo){

      } else {

      }

        if (this.filesInfo == ''){
          this.$store.state.ApplicationEdit.appEdit.offer = this.$store.state.ApplicationEdit.appEdit.offer
          console.log(this.$store.state.ApplicationEdit.appEdit)
          console.log(this.$store.state.ApplicationEdit.appEdit.tag)
          this.UploadDataDetails(this.$store.state.ApplicationEdit.appEdit)
          this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
        } if(this.filesInfo == 'update' || this.filesInfo == 'dell') {
          let formData = new FormData()
          if (this.filesInfo == 'update'){
            formData.append('image', this.files)
          } if(this.filesInfo == 'dell') {
            formData.append('image', this.noFiles)
          }

          formData.append('description', this.$store.state.ApplicationEdit.appEdit.description)
          formData.append('tittle', this.$store.state.ApplicationEdit.appEdit.tittle)
          formData.append('offer', this.$store.state.ApplicationEdit.appEdit.offer)
          formData.append('tag', this.$store.state.ApplicationEdit.appEdit.tag)
          formData.append('coach_name', this.$store.state.ApplicationEdit.appEdit.coach_name)

          this.axios({
            method: 'PUT',
            headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `${this.$store.state.options.address + this.$store.state.options.api.UploadImageDetails + this.$route.params.id}/`,
            data:formData,
          }).then(response =>{
            this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
            this.UploadDataDetails(this.$store.state.ApplicationEdit.appEdit)
          })
              .catch(function(error){
                console.log(error)
              })
        }

    },
    handleFileUpload(){
    	let that = this
    	that.files = event.target.files[0]
        let reader = new FileReader()
        reader.onload = function (e) {
            that.visionFile = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);      
        console.log(that.files)
        that.filesInfo = 'update'     
    },
    DeletedFile(){
        this.$store.state.ApplicationEdit.appEdit.image = ''
        this.filesInfo = 'dell'
        // this.axios({

        //     method: 'PUT',
        //     url: `${this.$store.state.options.address + this.$store.state.options.api.applicationDetails + this.$route.params.id}/`,
        //     data:data,
        //     }).then(response =>{
        //         console.log(response.data)
        //         this.GET_EDIT_DETAILS_API(this.$route.params.id)
        //     })
        //     .catch(function(error){
        //         console.log(error)         
        // }) 
    },
    selectOffer(offer){
      this.$store.state.ApplicationEdit.appEdit.offer = this.selectedOffer
      console.log(this.$store.state.ApplicationEdit.appEdit)
    }
  },
  computed:{
  	...mapGetters([
  		'APPLICATION_DETAILS',
      'getNav',

	]),
    useOffer:function(){
        return this.APPLICATION_DETAILS.Offer.filter(app => {return app.id == this.$store.state.ApplicationEdit.appEdit.offer})

    }
  }
};
</script>

<style scoped>
.danger {
  color:#ef4444;
  font-size: 12px;
  margin-left: 5px;
}
.danger-link {
  color:#ef4444;
  border-bottom:1px solid #ef4444;
  cursor:pointer;
}
.upload_file {
	text-align:center;
}
img {
    max-width:100%;
    max-height:400px;
}
.input{
    display:none;
}
.input__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background: #121127;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}
.offer-box {
  display: block;
  width:100%;
  height:auto;
  text-align: center;
  padding:5%;
}
.offers {
  padding:12px;
  font-size: 16px;
  cursor:pointer;
  border-radius:10px;
  color: #495057;
  border: 1px solid #ced4da;
  outline:none;
  width:100%;
}
.offers:focus {
  outline:none;
}
.offers option {
  font-size: 16px;
  padding:5px 0px;
}
.validate {
  display:block;
  margin-top: 10px;
  font-size: 13px;
  text-align: left;
}
.red {
  color:red;
}
</style>

