import { createStore } from 'vuex'
import axios from 'axios'
import options from '../options'


export default ({

  state: {
    ApplicationInfo:[],
  },
  modules: {
    options,
  },
  mutations: {
    set_application(state, data){
      state.ApplicationInfo = data
      console.log(data)
    },
    CreateApplication(state, data){
      axios({
          method:'POST',
          headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
          url:`${options.state.address + options.state.api.application}`,
          data:data,
      }).then(resp => {

        this.dispatch('GET_APLICATION_API');
      })
    },
    UpdateApplication(state, data){
      axios({
        method:'PUT',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.application + data.id}/`,
        data:data,
      }).then(resp => {

        this.dispatch('GET_APLICATION_API');
      })  

    },
    DeletedApplication(state, data){
      axios({
        method:'POST',
        headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
        url:`${options.state.address + options.state.api.DeletedApp}`,
        data:data,
      }).then(resp => {
        this.dispatch('GET_APLICATION_API');
      })   
    }
  },
  actions: {
    GET_APLICATION_API({state, commit}, data) {
    axios({
          method:'GET',
          headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
          url:`${options.state.address + options.state.api.application}`,
        })
        .then(product => {commit('set_application', product.data)})
        .catch(function(error){
                  console.log(error)         
        })        
      },

  },
  getters: {
    APPLICATION(state){
      return state.ApplicationInfo
    }
  },
})
