<template>
  <div class="editDetails">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2">
      <div class="card flex justify-content-center">
            <PanelMenu :model="getNav" class="w-full md:w-25rem" />
        </div>
        </div>
        <div class="col-md-10">
          <TabMenu v-model:activeIndex="active" :model="items" />

          <div class="row" v-if="active === 0">
            <div class="col-md-6">
              <div class="edit_tittle flex">
                <h2>Имя коуча</h2>
                <Textarea v-model="this.$store.state.ApplicationDetails.coachEdit.name" autoResize rows="3" cols="30" />
                <h2>Возраст</h2>
                <InputNumber v-model="this.$store.state.ApplicationDetails.coachEdit.age" inputId="integeronly" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Отображаемые изменения</h2>
                <p>{{this.$store.state.ApplicationDetails.coachEdit.name}}</p>
                <p>{{this.$store.state.ApplicationDetails.coachEdit.age}} лет</p>
              </div>
            </div>

        </div>

        <div class="row" v-if="active === 1">
                      <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Описание коуча</h2>
                <Textarea v-model="this.$store.state.ApplicationDetails.coachEdit.description" autoResize rows="5" cols="30" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Отображаемые изменения</h2>
                <p>{{this.$store.state.ApplicationDetails.coachEdit.description}}</p>
              </div>
            </div>

          </div>

          <div class="row" v-if="active === 2">
            
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Ссылка на коуча</h2>
                <Textarea v-model="this.$store.state.ApplicationDetails.coachEdit.link" autoResize rows="5" cols="30" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Отображаемые изменения</h2>
                <a :href="this.$store.state.ApplicationDetails.coachEdit.link" target="_blank">{{this.$store.state.ApplicationDetails.coachEdit.link}}</a>
              </div>
            </div>

          </div>

          <div class="row" v-if="active === 3">
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Промо описание</h2>
                <Editor v-model="this.$store.state.ApplicationDetails.coachEdit.promo" editorStyle="height: 320px"  >
            </Editor>
              </div>
            </div>
            <div class="col-md-6">
              <div class="edit_tittle">
                <h2>Отображаемые изменения</h2>
                <div v-html="this.$store.state.ApplicationDetails.coachEdit.promo"></div>
              </div>
            </div>
          </div>


          <div class="row" v-if="active === 4">
          <div class="col-md-12">
            <div class="row">
          <div class="col-md-6">
            <h2>Текущее изображение</h2>
              <div class="upload_file">
          <!-- this.$store.state.options.img_path + -->
              <img v-if="this.$store.state.ApplicationDetails.coachEdit.miniLogo" :src="this.$store.state.ApplicationDetails.coachEdit.miniLogo" alt="">
              
              <div class="input__wrapper" >
             <input type="file" @change="handleFileUploadMin()" :id="this.$store.state.ApplicationDetails.coachEdit.id" class="input">
             <label :for="this.$store.state.ApplicationDetails.coachEdit.id" class="input__file-button">
                <span class="input__file-icon-wrapper"></span>
                <span class="input__file-button-text">Выберите файл</span>
             </label>
              </div>
              <div   class="del input__file-button" @click="DeletedFileMin()">
                  <span class="input__file-icon-wrapper"></span>
                  <span class="input__file-button-text" >Удалить</span>
              </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="upload_file">
                <h2>Загруженное изображение</h2>
                <img :src="this.visionFile.minLogo" alt="">
              </div>
            </div>
            </div>
            </div>
          </div>


          <div class="row" v-if="active === 5">
            <div class="col-md-12">
            <div class="row">
          <div class="col-md-6">
            <h2>Текущее изображение</h2>
              <div class="upload_file">
          <!-- this.$store.state.options.img_path + -->
              <img v-if="this.$store.state.ApplicationDetails.coachEdit.bigLogo" :src="this.$store.state.ApplicationDetails.coachEdit.bigLogo" alt="">
              
              <div class="input__wrapper" >
             <input type="file" @change="handleFileUploadBig()" :id="this.$store.state.ApplicationDetails.coachEdit.id" class="input">
             <label :for="this.$store.state.ApplicationDetails.coachEdit.id" class="input__file-button">
                <span class="input__file-icon-wrapper"></span>
                <span class="input__file-button-text">Выберите файл</span>
             </label>
              </div>
              <div   class="del input__file-button" @click="DeletedFileBig()">
                  <span class="input__file-icon-wrapper"></span>
                  <span class="input__file-button-text" >Удалить</span>
              </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="upload_file">
                <h2>Загруженное изображение</h2>
                <img :src="this.visionFile.bigLogo" alt="">
              </div>
            </div>
            </div>
            </div>
          </div>

        </div>
        <div class="col-md-8 offset-md-2" >
          <div class="" style="text-align:right;">
            <Button @click="submitFile()" label="Сохранить Изменение" severity="success" />
          </div>
          
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { useToast } from 'primevue/usetoast';


import TabMenu from 'primevue/tabmenu';
import PanelMenu from 'primevue/panelmenu';
import InputText from 'primevue/inputtext';

import Button from 'primevue/button';
import Editor from 'primevue/editor';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';

export default {
  name:'CoachViewEditor',
  components: {
    TabMenu,
    PanelMenu,
    InputText,
    Button,
    Editor,
    Textarea,
    InputNumber
  },
  data(){
    return{
      active: 0,
      edit:{},
        items: [
            {
                label: 'Имя и возраст',
                icon: 'pi pi-fw pi-pencil'
            },
            {
                label: 'Описание',
                icon: 'pi pi-fw pi-align-justify'
            },
            {
                label: 'Ссылка',
                icon: 'pi pi-fw pi-link',
            },
            {
                label: 'Промо описание',
                icon: 'pi pi-fw pi-id-card'
            },
            {
                label: 'Маленькое лого',
                icon: 'pi pi-fw pi-image',
                command: () => {
                this.logoToggle = 'miniLogo'
              }
            },
            {
                label: 'Баннер',
                icon: 'pi pi-fw pi-image',
                command: () => {
                this.logoToggle = 'bigLogo'
              }
            },
        ],
        logoToggle:'',
        files:{minLogo:'', bigLogo:''},
        noFiles:{minLogo:'', bigLogo:''},
        filesInfo:{minLogo:'', bigLogo:''},
        visionFile:{minLogo:[], bigLogo:[]},
    }
  },
  mounted(){
    

    this.GET_COACH_API(this.$route.params.id)

  },
  created(){
    
  },
  methods:{
  ...mapMutations({
      UploadDataCoach:'UploadDataCoach',
  }),
  ...mapActions({
    GET_COACH_API:'GET_COACH_API',
    
  }),
      submitFile(){

      if (this.filesInfo.minLogo == '' && this.filesInfo.bigLogo == ''){
        this.UploadDataCoach(this.$store.state.ApplicationDetails.coachEdit)
        this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
      }
      setTimeout( () => {
        this.submitSecondLogo() 
      }, 1000)
      if(this.filesInfo.minLogo == 'update' || this.filesInfo.minLogo == 'dell') {
        let formData = new FormData()
        if (this.filesInfo.minLogo == 'update'){
          formData.append('miniLogo', this.files.minLogo)
        } if(this.filesInfo.minLogo == 'dell') {
          formData.append('miniLogo', this.noFiles.minLogo)
        }
        
        this.axios({
            method: 'PUT',
            headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `${this.$store.state.options.address + this.$store.state.options.api.CoachUpdateMiniLogo + this.$route.params.id}/`,
            data:formData,
            }).then(response =>{
                this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
                this.UploadDataCoach(this.$store.state.ApplicationDetails.coachEdit)
                this.filesInfo.minLogo = ''
                
            })
            .catch(function(error){
                console.log(error)         
        }) 
        } else {}    
    },
    submitSecondLogo(){
      if(this.filesInfo.bigLogo == 'update' || this.filesInfo.bigLogo == 'dell') {
        let formDatas = new FormData()
        if (this.filesInfo.bigLogo == 'update'){
          formDatas.append('bigLogo', this.files.bigLogo)
        } if(this.filesInfo.bigLogo == 'dell') {
          formDatas.append('bigLogo', this.noFiles.bigLogo)
        }
        
        this.axios({
            method: 'PUT',
            headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `${this.$store.state.options.address + this.$store.state.options.api.CoachUpdateBigLogo + this.$route.params.id}/`,
            data:formDatas,
            }).then(response =>{
                this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
                this.UploadDataCoach(this.$store.state.ApplicationDetails.coachEdit)
                this.filesInfo.bigLogo = ''
            })
            .catch(function(error){
                console.log(error)         
        }) 
        } else {}  
    },
    handleFileUploadMin(){
      let that = this
      that.files.minLogo = event.target.files[0]
        let reader = new FileReader()
        reader.onload = function (e) {
            that.visionFile.minLogo = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);      
        console.log(that.files)
        that.filesInfo.minLogo = 'update'     
    },
    handleFileUploadBig(){
      let that = this
      that.files.bigLogo = event.target.files[0]
        let reader = new FileReader()
        reader.onload = function (e) {
            that.visionFile.bigLogo = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);      
        console.log(that.files)
        that.filesInfo.bigLogo = 'update' 
    },
    DeletedFileMin(){
        this.$store.state.ApplicationDetails.coachEdit.miniLogo = ''
        this.filesInfo.minLogo = 'dell'
    },
    DeletedFileBig(){
        this.$store.state.ApplicationDetails.coachEdit.bigLogo = ''
        this.filesInfo.bigLogo = 'dell'
    }
  },
  computed:{
    ...mapGetters([
      'getNav',
  ]),

  }
};
</script>

<style scoped>

.upload_file {
  text-align:center;
}
img {
    max-width:100%;
    max-height:400px;
}
.input{
    display:none;
}
.input__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background: #121127;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}
</style>

