<template>
<!--   <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/application">application</router-link>
  </nav> -->
  <Toast/>
  <router-view/>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

import Toast from 'primevue/toast';

export default {
  name:'',
  components: {
  Toast,
  },
  data(){
    return{
    }
  },
  mounted(){
    if(localStorage?.getItem('token') !== '' || localStorage?.getItem('token') !== undefined){
      this.currentUser()
    } else {

    }
    // this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
  },
  created(){
    
  },
  methods:{
  ...mapMutations({
  }),
  ...mapActions({
    currentUser:'currentUser',
  }),
  },
  computed:{
    ...mapGetters([
  ]),
  }
};
</script>

<style>
#app {
  font-family: 'Montserrat';
  text-align: left;
  color: #2c3e50;
}
.mt-5{
margin-top: 5%;
}
.ml-5 {
  margin-left: 5%;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #121127;
}

nav a.router-link-exact-active {
  color: #121127;
}
</style>
