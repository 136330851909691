import { createStore } from 'vuex'
import axios from 'axios'
//Modules


// https://api.tarasov-app-baza.ru/
export default createStore({
  modules: {
  },
  // https://api.tarasov-app-baza.ru/api/
  state: {
    address:'https://api.tarasov-app-baza.ru/api/',
    api:{
      ApplicationUserDetail:'ApplicationUserDetail/',
      allUser:'users/',
      optionsAppUser:'optionsAppUser',
      register:'register/',
      currentUser:'currentUser/',
      application:'Application/',
      ApplicationDepth:'ApplicationDepth/',
      applicationDetails:'ApplicationDetails/',
      AddNewAppDetails:'AddNewAppDetails/',
      DeletedManyAppDetails:'DeletedManyAppDetails/',
      DeletedApp:'DeletedApp/',
      UploadImageDetails:'UploadImageDetails/',
      UploadDataDetails:'UploadDataDetails/',
      Offer:'Offer/',
      AddNewOffer:'AddNewOffer/',
      DeletedManyOffer:'DeletedManyOffer/',
      UploadImageOffer:'UploadImageOffer/',
      UploadDataOffer:'UploadDataOffer/',
      Event:'Event/',
      AddNewEvent:'AddNewEvent/',
      DeletedManyEvent:'DeletedManyEvent/',
      UploadImageEvent:'UploadImageEvent/',
      UploadDataEvent:'UploadDataEvent/',
      Audio:'Audio/',
      AddNewAudio:'AddNewAudio/',
      DeletedManyAudio:'DeletedManyAudio/',
      UploadImageAudio:'UploadImageAudio/',
      UploadDataAudio:'UploadDataAudio/',
      CoachAllInfoView:'CoachAllInfoView/',
      AddNewCoach:'AddNewCoach/',
      DeletedManyCoach:'DeletedManyCoach/',
      CoachUpdateData:'CoachUpdateData/',
      CoachUpdateMiniLogo:'CoachUpdateMiniLogo/',
      CoachUpdateBigLogo:'CoachUpdateBigLogo/',
    }
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
})
