<template>

  <div class="authorization">
  	<div class="container">
  		<div class="row">
  			<div class="col-md-8 offset-md-2">
  				<div class="form">
  					<p class="auth_desc">Логин</p>
  					<input type="text" v-model="form_data.username" placeholder="Логин" class="input_auth">
  					<p class="auth_desc">Пароль</p>
  					<input type="password" v-model="form_data.password" placeholder="Пароль" class="input_auth" @keyup.enter="authorization()">
  					<button class="auth_btn" @click="authorization()">Авторизация</button>
<!--   					<button class="auth_btn" @click="checkSession()">Check</button>
  					<button class="auth_btn" @click="session()">session()</button> -->
  				</div>
  			</div>
  		</div>
  	</div>
  </div>
</template>

<script>



import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { useToast } from 'primevue/usetoast';
export default {
  name: 'authorization',
  components: {
  },
    data(){
    return {
    	form_data:{
    		username:'',
    		password:'',
    	}
    }
  },
  mounted(){
  	// this.axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('Authorization');
  },
  methods:{
  	...mapMutations({

  	}),
  	...mapActions({
      currentUser:'currentUser',
  	}),
  	authorization(){
  		// console.log(this.form_data)
  		this.axios({
          	method: 'POST',
            url: `https://api.tarasov-app-baza.ru/auth/token/login/`,
            data:this.form_data,
        }).then(response =>{
            // console.log(response)
            localStorage.setItem('Authorization', response.data.auth_token);
            this.$router.push({path:'/'})
            this.currentUser()
        })
        .catch(function(error){
            // this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
            console.log(error)         
        })
  	},
  	checkSession(){
		this.axios({
          	method: 'GET',
          	headers:{
          		Authorization:'Token ' + localStorage.getItem('Authorization')
          	},
            url: `https://api.tarasov-app-baza.ru` + `${this.$store.state.options.api.application}`,
        }).then(response =>{
            console.log(response.data)

        })
        .catch(function(error){
            console.log(error)         
        })
  	},
  	session(){
  		this.axios({
          	method: 'GET',
          	headers:{
          		Authorization:'Token ' + localStorage.getItem('Authorization')
          	},
            url: `https://api.tarasov-app-baza.ru/AutSession/`,
        }).then(response =>{
            console.log(response.data)
        })
        .catch(function(error){
            console.log(error)         
        })
  	}
  },
  computed:{
  	...mapGetters([

  	]),
  }
}
</script>

<style scoped>
.input_auth{
	border:1px solid rgba(18, 17, 39, 0.12);
	width:100%;
	padding:15px 0px 15px 20px;
	font-size:18px;
	color:#121127;
	opacity:0.6;
	background:#fff;
	outline:none;
	border-radius:5px;
}
.auth_desc{
	margin-bottom:4px;
	color:#121127;
	font-size:14px;
	font-weight:bold;
	opacity:0.6;
}
.form{
	padding:15%;
	text-align:left;
	margin-top:15%;
}
.auth_btn{
	font-size:20px;
	color:#fff;
	text-align:center;
	background:#444BD3;
	border-radius:5px;
	outline:none;
	border:none;
	width:100%;
	padding:15px 0px 15px 0px;
	cursor:pointer;
	margin-top:32px;
}
</style>


