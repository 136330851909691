import { createStore } from 'vuex'
import axios from 'axios'
import options from '../options'


export default ({
  state: {
  	appEdit:{},
  },
  modules: {
    options,

  },
  mutations: {
  	set_editApp(state, data){
  		state.appEdit = data
  	},
  },
  actions: {
  	GET_EDIT_DETAILS_API({state, commit}, data) {
    axios({

          method:'GET',
          headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
          url:`${options.state.address + options.state.api.applicationDetails + data + '/'}`,
        })
        .then(product => {commit('set_editApp', product.data)})
        .catch(function(error){
                  console.log(error)         
        })        
      },
  },
  getters: {

  },
})
