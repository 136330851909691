import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthorizationView from '../views/AuthorizationView.vue'
import ApplicationView from '../views/ApplicationView.vue'
import EditDetailsView from '../views/EditDetailsView.vue'
import OfferView from '../views/OfferView.vue'
import OfferEditor from '../views/OfferEditorView.vue'
import EventView from '../views/EventView.vue'
import EventEditor from '../views/EventEditorView.vue'
import AudioView from '../views/AudioView.vue'
import AudioEditorView from '../views/AudioEditorView.vue'
import CoachView from '../views/CoachView.vue'
import CoachViewEditor from '../views/CoachViewEditor.vue'
import axios from 'axios'
import options from '../store/options.js'
import userList from '../views/usersList.vue'
import registration from "@/views/registrationView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{auth:true},
  },
  {
    path: '/authorization',
    name: 'auth',
    component: AuthorizationView,
  },
  {
    path: '/registration/:id',
    name: 'registration',
    component: registration,
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: EditDetailsView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/application/:id',
    name: 'app',
    component: ApplicationView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/Offer/:id',
    name: 'Offer',
    component: OfferView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/OfferEditor/:id',
    name: 'OfferEditor',
    component: OfferEditor,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/Event/:id',
    name: 'Event',
    component: EventView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/EventEditor/:id',
    name: 'EventEditor',
    component: EventEditor,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/Audio/:id',
    name: 'Audio',
    component: AudioView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/AudioEditor/:id',
    name: 'AudioEditor',
    component: AudioEditorView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/Coach/:id',
    name: 'Coach',
    component: CoachView,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/CoachEditor/:id',
    name: 'CoachEditor',
    component: CoachViewEditor,
    query:{categories:''},
    meta:{auth:true},
  },
  {
    path: '/userList/:id',
    name: 'userList',
    component: userList,
    query:{categories:''},
    meta:{auth:true},
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
   // const Oauth = to.matched.some(record => record.meta.auth)

    //if (Oauth && !store.mutations.SESSION()){
    //    next('/about')
   // } else {
   //     next()
    //}


    if (to.matched.some(record => record.meta.auth)) {
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу логина
    axios({
            method: 'GET',
            headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `${options.state.address}AutSession/`,
        }).then(response =>{
            console.log(response)
            if (response.data == true) {
                next()
            }
        }).catch(function(error){
            console.log(error) 
            next({
              path: '/authorization',
            })        
        })
    
  } else {
    next() // всегда так или иначе нужно вызвать next()!
  }
})



export default router
