import { createStore } from 'vuex'
import axios from 'axios'
import options from '../options'

export default ({
  state: {
  	audioEdit:[],
  },
  modules: {
    options,
  },
  mutations: {
    set_audio(state, data){
      state.audioEdit = data
      console.log(data)
    },
    // CreateOffer(state, data){
    //   axios({
    //       method:'POST',
    //       headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
    //       url:`${options.state.address + options.state.api.AddNewOffer}`,
    //       data:data,
    //   }).then(resp => {
    //     this.dispatch('GET_APLICATION_DETAILS_API');
    //   })
    // },
    // DeletedOffer(state, data){
    //   axios({
    //     method:'DELETE',
    //     headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
    //     url:`${options.state.address + options.state.api.Offer + data}/`,
    //     data:data,
    //   }).then(resp => {
    //     this.dispatch('GET_APLICATION_DETAILS_API');
    //   })   
    // },
    // DeletedManyOffers(state, data){
    //   axios({
    //     method:'POST',
    //     headers:{Authorization:'Token ' + localStorage.getItem('Authorization')},
    //     url:`${options.state.address + options.state.api.DeletedManyOffer}`,
    //     data:data,
    //   }).then(resp => {
    //     this.dispatch('GET_APLICATION_DETAILS_API');
    //   })   
    // }
  },
  actions: {
    GET_AUDIO_API({state, commit}, data) {
    axios({
          method:'GET',
          headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
          url:`${options.state.address + options.state.api.Audio + data}/`,
        })
        .then(product => {commit('set_audio', product.data)})
        .catch(function(error){
                  console.log(error)         
        })        
      },
  // GET_APLICATION_DETAILS_API({state, commit}, data) {
  //   axios({

  //         method:'GET',
  //         headers:{
  //             Authorization:'Token ' + localStorage.getItem('Authorization')
  //           },
  //         url:`${options.state.address + options.state.api.ApplicationDepth + state.id + '/'}`,
  //       })
  //       .then(product => {commit('set_applicationDetails', product.data), commit('itemPaginate')})
  //       .catch(function(error){
  //                 console.log(error)         
  //       })        
  //     },
  },
  getters: {

  },
})
