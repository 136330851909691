<template >
  <div id="application" class="container-fluid">
  	<div class="row">
  		<div class="col-md-2 mt-5">
			<div class="card flex justify-content-center">
		        <PanelMenu :model="getNav" class="w-full md:w-25rem" />
		    </div>
  		</div>
  		<div class="col-md-10">
  			<h1>Контент приложений</h1>
  			<div class="btn-box">
  				<div class="row">
  					<div class="col-md-4">
  						<div class="button-box">
  							<div><Button @click="CreateApplicationDetails(this.addNewAppData), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 })" icon="pi pi-plus" severity="success" aria-label="Create" /></div>
<!--   							<div class="ml-5"><Button calss="ml-5" icon="pi pi-check" severity="secondary" aria-label="All" /></div>
  							<div class="ml-5"><Button calss="ml-5" icon="pi pi-trash" severity="danger" aria-label="Dell" /></div> -->
  						</div>

  					</div>
  					<div class="col-md-8">
		  				<SelectButton v-model="btnActive" @change="console.log(btnActive)" :layout="btnActive" :options="btn" optionLabel="value" dataKey="value" aria-labelledby="custom">
				            <template #option="slotProps">
				                <i :class="slotProps.option.icon"></i>
				            </template>
			        	</SelectButton>
  					</div>
  				</div>
  			</div>
        <div class="search">
          <InputText style="width:100%;" type="text" @input="inputChange" v-model="this.$store.state.ApplicationDetails.search" placeholder="Поиск..."/>
        </div>

        <!-- tags -->
        <div class="col-md-12 m-10">
          <Button type="button" label="#Все" class="ml-10" @click="updateTag(''), this.itemFilterTag()"/>
          <template v-for='(app, index) of this.tagz' :key='index'>
            <Button v-if="app.tag != ''" type="button" :label="'#' + app.tag" class="ml-10" @click="updateTag(app.tag), this.itemFilterTag()"/>
          </template>
          
        </div>
      <!-- tags -->
			<div class="nav-box">
				<div class="check-box">	
  						<div class=""><Checkbox v-model="selectAll" @change="addTobasket()" :binary="true" />
  						<label for="ingredient1" class="ml-2"> Выделить все </label></div>
  						<Button  icon="pi pi-trash" label="Удалить выделенное" @click="this.delAllvisible = true" aria-label="edit" severity="danger" />
  					</div>
  				</div>
  			<div v-if="btnActive.value == 'table'" class="box-grid">
  				
  				<div class="card-table" v-for='(app, index) of this.paginateItems' :key='index'>
  					<div class="row">
  						<div class="col-md-3" >
  							<div class="img-box">
  								<img :src="app.image" alt="">
  							</div>
  						</div>
  						<div class="col-md-4">
  							<div class="description-box">
  								<div class="tittle">{{app.tittle}} </div>
  								<ul class='message'>
  									<li><InlineMessage v-if="app.description != ''" severity="success">Есть Описание</InlineMessage></li>
  									<li><InlineMessage v-if="app.description == ''" severity="error">Описания нет</InlineMessage></li>
  									<li><InlineMessage v-if="!app.video" severity="error">Видео не добавлено</InlineMessage></li>
  									<li><InlineMessage v-if="app.video" severity="success">Есть видео</InlineMessage></li>
  									<li><InlineMessage v-if="!app.offer" severity="error">Рекламы нет {{}}</InlineMessage></li>
                    <li><InlineMessage v-if="app.offer" severity="success">Есть Реклама</InlineMessage></li>
                    <li><InlineMessage severity="info">{{ getDate(app.date) }}</InlineMessage></li>
  								</ul>
<!--   								<InlineMessage v-if="app.description != ''" severity="success">Есть Описание</InlineMessage>
  								<InlineMessage v-if="app.description == ''" severity="error">Описания нет</InlineMessage>
  								<InlineMessage v-if="!app.video_link" severity="error">Видео не добавлено</InlineMessage>
  								<InlineMessage v-if="app.video_link" severity="success">Есть видео</InlineMessage> -->
  							</div>
  						</div>
  						<div class="col-md-3 offset-md-2">
  							<div class="table-nav-box">
  								<div class="checked">
  									<Checkbox v-model="selectedID" :inputId="app.tittle" :value="app.id" />
  								</div>
  								<div class="button">
  									<Button  icon="pi pi-pencil" @click="goEdit(app.id)"  aria-label="edit" severity="secondary" />
  									
  								</div>
  								<div class="button"><Button @click="this.visible = true, this.dellData = app.id" icon="pi pi-trash" severity="danger" aria-label="Dell"/></div>
  							</div>
  							
  						</div>
  					</div>
  				</div>
  			</div>

  			<div v-if="btnActive.value == 'box'" class="box-layout">
  				<div class="row">
  					<div class="col-md-6 col-xl-3" v-for='(app, index) of this.paginateItems' :key='index'>
  						<div class="box-cart">
  							<div class="box-head">
  								<div class="checked">
  									<Checkbox v-model="selectedID" :inputId="app.tittle" :value="app.id"  />
  								</div>
  							</div>
  							<div class="box-body">
  								<img :src="app.image" alt="">
  								
  								<div class="tittle">{{app.tittle}}</div>
  								
  							</div>
  							<div class="box-footer">
  								<div class="infobox">
  									<ul>
									<li><InlineMessage v-if="app.description != ''" severity="success">Есть Описание</InlineMessage></li>
  									<li><InlineMessage v-if="app.description == ''" severity="error">Описания нет</InlineMessage></li>
  									<li><InlineMessage v-if="!app.video" severity="error">Видео не добавлено</InlineMessage></li>
  									<li><InlineMessage v-if="app.video" severity="success">Есть видео</InlineMessage></li>
                    <li><InlineMessage v-if="!app.offer" severity="error">Рекламы нет {{}}</InlineMessage></li>
                    <li><InlineMessage v-if="app.offer" severity="success">Есть Реклама</InlineMessage></li>
  									</ul>
  								</div>
  								<div class="btn-cart" >
	  								<Button  icon="pi pi-pencil" @click="goEdit(app.id)"  aria-label="edit" severity="secondary" />
	  								<Button @click="this.visible = true, this.dellData = app.id" icon="pi pi-trash" severity="danger" aria-label="Dell"/>
  								</div>
  								
  							</div>
  						</div>
  					</div>
  				</div>
  			</div>


  			<Dialog v-model:visible="this.visible" modal header="Удалить Контент?" :style="{ width: '50vw' }">
	          <p>
	            Все данные будут удалены безвозвратно, вместе свидео..
	          </p>
	          <template #footer>
	              <Button label="НЕТ" icon="pi pi-times" @click="this.visible = false" text />
	              <Button label="ДА" icon="pi pi-check" @click="this.visible = false, DeletedApplicationDetails(this.dellData), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });" autofocus />



	          </template>
	      </Dialog>
	      <Dialog v-model:visible="this.delAllvisible" modal header="Удалить Контент?" :style="{ width: '50vw' }">
	          <p>
	            Все выделенные данные будут безвозвратно удалены!
	          </p>
	          <template #footer>
	              <Button label="НЕТ" icon="pi pi-times" @click="this.delAllvisible = false" text />
	              <Button label="ДА" icon="pi pi-check" @click=" this.delAllvisible = false, this.selectAll = false, DeletedManyAppDetails(this.selectedID), this.$toast.add({ severity: 'success', summary: 'Отлично!', detail: 'Комманда успешно завершена!', life: 3000 });" autofocus />



	          </template>
	      </Dialog>

			<div class="container" style="margin-top:5%;">
				<div class="row">
					<div class="col-md-12">
	<!-- 					<Paginator :rows="this.pageInfo.size" v-model:first="this.pageInfo.page" :totalRecords="this.pageLimit" :space="this.nextPage(pageInfo)"></Paginator> -->
						<paginate 
							v-model="this.page"
							:page-count="this.PAGE_COUNT"
							:click-handler="updatePage"
							:prev-text="'<<'"
							:next-text="'>>'"
							:container-class="'pagination'"
							:page-class="'page-item'"
						/>
					</div>
				</div>
			</div>
  		</div>
  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import InputText from 'primevue/inputtext';
import PanelMenu from 'primevue/panelmenu';
import SelectButton from 'primevue/selectbutton';
import InlineMessage from 'primevue/inlinemessage';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import Dialog from 'primevue/dialog';
import paginate from '../components/paginate.vue'
import ApplicationDetails from "@/store/ApplicationDetails";

export default {
  name:'',
  components: {
  	PanelMenu,
  	SelectButton,
  	InlineMessage,
  	Checkbox,
  	Button,
    Badge,
  	paginate,
  	Dialog,
    InputText,
  },
  data(){
  	return{
  		page:1,
  		visible:false,
  		delAllvisible:false,
  		selectAll:false,
  		selectedID:[],
  		dellData:'',
  		app_id:this.$route.params.id,
  		addNewAppData:{
  			'app_id':this.$route.params.id, 
  			"tittle": "Заполните Название", 
  			"description": "Заполните описание", 
  			"image":'photos/noImage.png', 
  			"video": "",
        'tag':this.$store.state.tagActive || '',
        'coach_name':'',
  		},
  		checked:false,
  		btnActive:{value:'table'},
  		btn:[	
  				{ icon: 'pi pi-align-justify', value: 'table'  },
                { icon: 'pi pi-th-large', value: 'box'  },
                
            ],
        layout: false,
  	}
  },
  mounted(){
  	this.setIdApp(this.app_id)
  	this.GET_APLICATION_DETAILS_API()
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
  	CreateApplicationDetails:'CreateApplicationDetails',
  	DeletedApplicationDetails:'DeletedApplicationDetails',
  	DeletedManyAppDetails:'DeletedManyAppDetails',
  	itemPaginate:'itemPaginate',
    itemFilterTag:'itemFilterTag',
  	setIdApp:'setIdApp',
  	nextPage:'nextPage',
    activeTag:'activeTag',
	}),
	...mapActions({
		GET_APLICATION_DETAILS_API:'GET_APLICATION_DETAILS_API',
	}),
    inputChange(e) {
      this.itemFilterTag()
    },
    getDate(date){
      const dateTime = new Date(date);
      const formattedDate = dateTime.toLocaleDateString();
      return formattedDate
    },
    updateTag(tag){
      this.activeTag(tag)
      this.addNewAppData.tag = tag
    },
	goEdit(id){
      this.$router.push({ path: `/edit/${id}`, query: {}})
    },
	updatePage(page){
		this.page = page
		this.nextPage(this.page)
		console.log(this.page)
	},
	addTobasket(){		
		let data = this.APP_PAGINATE_DETAILS

		for (let i=0; i < data.length; i++) {
			let index = this.selectedID.indexOf(data[i].id)
			if (this.selectAll){
				this.selectedID.push(data[i].id)
			} else {
				this.selectedID.splice(index, 1);	  	
			}
				
		}
		let listID = this.selectedID.filter((item, index) => {
		    return this.selectedID.indexOf(item) === index
		});
		this.selectedID = listID
		console.log(this.selectedID )
	},
  },
  computed:{
    ApplicationDetails() {
      return ApplicationDetails
    },
  	...mapGetters([
  		'APPLICATION_DETAILS',
  		'APP_PAGINATE_DETAILS',
  		'PAGE_COUNT',
      'GET_ACTIVE_TAG',
      'getNav',
	]),
	pageLimit:function(){
		return this.APP_PAGINATE_DETAILS.length
	},
	paginateItems:function(){
		return this.APP_PAGINATE_DETAILS
	},
  tagz:function(){
    return _.uniqBy(this.APPLICATION_DETAILS.app, 'tag')
  },
  }
};
</script>

<style scoped>
#application {
	padding-top:2%;
}
.btn-box {
	background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    display: block;
    text-align: right;
}
.card-table{
	width:100%;
	height:auto;
    border-bottom: 1px solid #dee2e6;
}
.card-table:first-child{
	border-top: none;
}
.img-box {
	padding:5%;
	display:flex;
	align-items: center;
	height:100%	;
}
.img-box img{
	width:100%;
	height:auto;
}
.description-box {

}
.tittle {
	font-size:18px;
	margin-top: 5%;
}
.video, .description {
	display:block;
	position: relative;
	margin-top: 10px;
	font-size:16px;
	
}
.video:before, .description:before {
	content: '';
	position: absolute;
	width:200px;
	bottom:-5px;
	border-bottom:2px solid #dee2e6;
}
.message {
	padding:0px;
}
.message li {
	list-style-type: none;
	margin-top: 5px;
}
.table-nav-box {
	margin-top: 5%;
	padding-right: 10%;
}
.checked {
	display:block;
	text-align: right;
}
.button {
	margin:10px 0px 10px 0px;
	display: block;
	text-align: right;
}
.box-body {
	width:100%;
	height:auto;
	display:block;
	margin-bottom: 5%;
}
.box-body img {
	min-height: 200px;
}
.box-cart {
	border: 1px solid #dee2e6;
	border-radius:8px;
	padding:5%;
	margin-top: 5%;
	position: relative;
}
.box-head {
	position: absolute;
	right:5%;
}
.box-body img{
	width:100%;
	height:auto;
	padding-top: 0px;
	margin-top: 0px;
}
.box-body .tittle {
	text-align: left;
	margin-top: 10px;
	margin-bottom: 15px;
	max-width: 90%;
	font-size:18px;
}
.box-footer {
	display:block;
	text-align: center;
}
.infobox ul {
	padding:0px;
	display:inline-block;
	margin: 0px;
	margin-bottom: 10px;
	width:100%;
}
.infobox ul li {
	list-style-type: none;
	margin: 5px;
	text-align: left;
}
.btn-cart {
	width:100%;
	height: auto;
	display: flex;
	justify-content: space-around;
}
.button-box {
	display:flex;
	align-items: flex-start;
}
.nav-box {
	margin-left: 2%;
	margin-top: 1%;
}
.check-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.m-10 {
  margin:10px 0;
}
.ml-10 {
  margin-left: 10px;
}
.search {
  display:flex;
  width:100%;
  margin:5px 0px;
}
</style>

