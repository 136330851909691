<template>
  <div class="container-fluid">
  	<div class="row">
		<div class="col-md-2">
			<div class="card flex justify-content-center">
		        <PanelMenu :model="getNav" class="w-full md:w-25rem" />
		    </div>
		</div>
		<div class="col-md-10">
			<div class="col-md-10">
  				<TabMenu v-model:activeIndex="active" :model="items" />


  				<div class="row" v-if="active === 0">
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Изменить название проекта</h2>
		  					<Textarea v-model="this.$store.state.Event.eventEdit.tittle" autoResize rows="5" cols="30" />
		  				</div>
  					</div>
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Отображаемые изменения</h2>
		  					<p>{{this.$store.state.Event.eventEdit.tittle}}</p>
		  				</div>
  					</div>
  				</div>

  				<div class="row" v-if="active === 1">
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Изменить название проекта</h2>
		  					<Textarea v-model="this.$store.state.Event.eventEdit.description" autoResize rows="5" cols="30" />
		  				</div>
  					</div>
  					<div class="col-md-6">
		  				<div class="edit_tittle">
		  					<h2>Отображаемые изменения</h2>
		  					<p>{{this.$store.state.Event.eventEdit.description}}</p>
		  				</div>
  					</div>
  				</div>

  				<div class="row" v-if="active === 2">
  					<div class="col-md-6" >
  						<h2>Изменить описание проекта</h2>
						<Textarea v-model="this.$store.state.Event.eventEdit.link" autoResize rows="5" cols="30" />

  					</div>
  					<div class="col-md-6">
  						<h2>Отображаемые изменения</h2>
  						<a :href="this.$store.state.Event.eventEdit.link" target="_blank">{{this.$store.state.Offer.offerEdit.link}}</a>
  					</div>
  				</div>


  				<div class="row" v-if="active === 3">
  					<div class="col-md-12">
  					<div class="row">
					<div class="col-md-6">
						<h2>Текущее изображение</h2>
					  	<div class="upload_file">
					<!-- this.$store.state.options.img_path + -->
					    <img v-if="this.$store.state.Event.eventEdit.image" :src="this.$store.state.Event.eventEdit.image" alt="">
					    
					    <div class="input__wrapper" >
					   <input type="file" @change="handleFileUpload()" :id="this.$store.state.Event.eventEdit.id" class="input">
					   <label :for="this.$store.state.Event.eventEdit.id" class="input__file-button">
					      <span class="input__file-icon-wrapper"></span>
					      <span class="input__file-button-text">Выберите файл</span>
					   </label>
					    </div>
					    <div   class="del input__file-button" @click="DeletedFile()">
					        <span class="input__file-icon-wrapper"></span>
					        <span class="input__file-button-text" >Удалить</span>
					    </div>
					  </div>
						</div>
						<div class="col-md-6">
							<div class="upload_file">
								<h2>Загруженное изображение</h2>
								<img :src="this.visionFile" alt="">
							</div>
						</div>
  					</div>
  					</div>	
  				</div>

  				<div class="row" v-if="active === 4">
  					<div class="col-md-6" >
  						<h2>Дата начала</h2>

              <div class="flex">
                <input class="date" v-model="this.$store.state.Event.eventEdit.date_on" type="date" name="calendar">
                <p>{{this.$store.state.Event.eventEdit.date_on}}</p>
              </div>
              <p>Начало мероприятия в часах {{this.$store.state.Event.eventEdit.time_on}}</p>
              <div class="flex">
                <input class="time" v-model="this.$store.state.Event.eventEdit.time_on" type="time" required />

              </div>



  					</div>
  					<div class="col-md-6">
  						<h2>Дата окончания</h2>
              <div class="flex">
                <input class="date" v-model="this.$store.state.Event.eventEdit.date_off" type="date" name="calendar">
                <p>{{this.$store.state.Event.eventEdit.date_off}}</p>
              </div>

              <p>Конец мероприятия в часах {{this.$store.state.Event.eventEdit.time_off}}</p>
              <div class="flex">
                <input class="time" v-model="this.$store.state.Event.eventEdit.time_off" type="time" required />
              </div>
  					</div>
  				</div>

  			</div>

  			<div class="col-md-8 offset-md-2" >
  				<div class="" style="text-align:right;">
  					<Button @click="submitFile()" label="Сохранить Изменение" severity="success" />
  				</div>
  				
  			</div>

		</div>

  	</div>
  </div>

</template>


<script>
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';


import TabMenu from 'primevue/tabmenu';
import PanelMenu from 'primevue/panelmenu';
import InputText from 'primevue/inputtext';

import Button from 'primevue/button';
import Editor from 'primevue/editor';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';

export default {
  name:'',
  components: {
  	TabMenu,
  	PanelMenu,
  	InputText,
  	Button,
  	Editor,
  	Textarea,
  	Calendar
  },
  data(){
  	return{
  		active: 0,
  		edit:{},
  		date_to:'',
  		date_off:'',
        items: [
            {
                label: 'Название',
                icon: 'pi pi-fw pi-pencil'
            },
            {
                label: 'Описание',
                icon: 'pi pi-fw pi-align-justify'
            },
            {
                label: 'Ссылка',
                icon: 'pi pi-fw pi-link'
            },
            {
                label: 'Изображение',
                icon: 'pi pi-fw pi-image'
            },
            {
                label: 'Дата проведения',
                icon: 'pi pi-fw pi-calendar-plus'
            },
        ],
        files:'',
        noFiles:'',
        filesInfo:'',
        visionFile:[],
  	}
  },
  mounted(){
  	this.GET_EVENT_API(this.$route.params.id)
  },
  created(){
  	
  },
  methods:{
  ...mapMutations({
  		UploadDataEvent:'UploadDataEvent',
	}),
	...mapActions({
		GET_EVENT_API:'GET_EVENT_API'
	}),
	submitFile(){
	    if (this.filesInfo == ''){
	    	this.UploadDataEvent(this.$store.state.Event.eventEdit)
	    	this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
	    } if(this.filesInfo == 'update' || this.filesInfo == 'dell') {
        let formData = new FormData()
        if (this.filesInfo == 'update'){
        	formData.append('image', this.files)
        } if(this.filesInfo == 'dell') {
        	formData.append('image', this.noFiles)
        }
        
        formData.append('tittle', this.$store.state.Event.eventEdit.tittle)
        formData.append('link', this.$store.state.Event.eventEdit.link)
        
        this.axios({
            method: 'PUT',
          	headers:{
              Authorization:'Token ' + localStorage.getItem('Authorization')
            },
            url: `${this.$store.state.options.address + this.$store.state.options.api.UploadImageEvent + this.$route.params.id}/`,
            data:formData,
            }).then(response =>{
                this.$toast.add({ severity: 'info', summary: 'Отлично!', detail: 'Обновления вступили в силу!', life: 3000 });
                this.UploadDataEvent(this.$store.state.Event.eventEdit)
            })
            .catch(function(error){
                console.log(error)         
        }) 
        }     
    },
    handleFileUpload(){
    	let that = this
    	that.files = event.target.files[0]
        let reader = new FileReader()
        reader.onload = function (e) {
            that.visionFile = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);      
        console.log(that.files)
        that.filesInfo = 'update'     
    },
    DeletedFile(){
        this.$store.state.Offer.offerEdit.image = ''
        this.filesInfo = 'dell'
        // this.axios({

        //     method: 'PUT',
        //     url: `${this.$store.state.options.address + this.$store.state.options.api.applicationDetails + this.$route.params.id}/`,
        //     data:data,
        //     }).then(response =>{
        //         console.log(response.data)
        //         this.GET_EDIT_DETAILS_API(this.$route.params.id)
        //     })
        //     .catch(function(error){
        //         console.log(error)         
        // }) 
    }

  },
  computed:{
  	...mapGetters([
      'getNav',
	]),
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}
.time {
  width: 100px;
  height: 50px;
  font-size: 18px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #000;
}
.upload_file {
	text-align:center;
}
img {
    max-width:100%;
    max-height:400px;
}
.input{
    display:none;
}
.input__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background: #121127;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}
.date {
  height:40px;
  width: 200px;
  font-size: 16px;
  text-align: left;
  padding: 0px 20px;
  border-radius: 6px;
  border-color:#2c3e50;
  cursor:pointer;
}
</style>

